import $ from 'jquery';
import { spinner } from "@/utils/spinner";
import { showToast } from "@/utils/toast";


export function loadAsyncVars(d) {
	d = d[0];
	$("*[async]").each(function() {
		if ($(this).is('input')) {
			if ($(this).attr("type") == "checkbox") {
				if (d[$(this).attr("name")] == '1') {
					$(this).prop("checked",true)
				}
			} else {
				$(this).attr('value',d[$(this).attr("name")]);
			}
			
		} else if ($(this).is('select')) {
			$(this).find("option[value="+d[$(this).attr("name")]+"]").prop('selected', true);
		} else {
			$(this).html(d[$(this).attr("name")]);
		}
		
	});
}

export default function ajaxRequest(obj) {
	
	var POST = {};
	var showLoader = true;
	
	// for (var task in cron_tasks) {
	// 	obj[task] = cron_tasks[task];
	// }
	
	for (var req in obj) {
		let tmp;
		if ("undefined" === typeof(obj[req].data)) {
			tmp = {};
		} else {
			tmp = obj[req].data
		}
		POST[req] = {
			task : obj[req].task,
			data : JSON.stringify(tmp)
		};
		if (obj[req].hideLoader == true) { showLoader = false; }
	}
	
	if (showLoader)
		spinner(1);
		
	const token = JSON.parse(localStorage.getItem("vuex")).token

	let uri;
	if (token === null) {
		uri = process.env.VUE_APP_ENDPOINT + "/";
	} else {
		uri = process.env.VUE_APP_ENDPOINT + "/?token="+token;
	}
	$.ajax({
		url:uri,
		method:'post',
		data: POST,
		crossDomain: true,
		xhrFields: { withCredentials: true },
		cache: false,
		dataType:'json',
		success:function(D) {
			
			spinner(0);
			if (D.error) {
				if (D.error == 'auth_required') {
					sessionStorage.setItem("sspa_after_login_redirect",window.location.pathname+window.location.search)
                    localStorage.removeItem("vuex")
					window.location.reload()

					return;
				} else {
                    showToast({
                        title : "errore",
                        content : "errore",
                        type : "error"
                    })
                }
					
			} else {
							
				for (var res in D) {
					if (D[res].error) {
						
						showToast({
							title : 'Si è verificato un errore',
							content : D[res].error+(D[res].code ? '<br><small>Codice errore <b>'+D[res].code+'</b></small>' : ''),
							type: "error"
						});
						
						return;
					}
					
					if ('function' === typeof(obj[res].callback))
						obj[res].callback(D[res]);
					else
						loadAsyncVars(D[res]);
				}
				
			}
		},
		statusCode : {

			// 404 : function() {
			// 	// showAlert("Impossibile trovare il modulo "+name, "danger");
			// 	ajaxLoader(0);
			// },
			// 403 : function() {
			// 	showAlert("Non hai i permessi per effettuare questa azione.", "danger");
			// 	ajaxLoader(0);
			// },
			// 500 : function() {
			// 	showAlert("Si è verificato un errore.", "danger");
			// 	ajaxLoader(0);
			// }
		},
		error:function() {
			showToast({
                title : "Errore",
                content : "Errore",
                type : "error"
            })
			spinner(0);
		}
	});	
}