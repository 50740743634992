

import { showToast } from './toast';
import msgbox from './msgbox';
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import ajaxRequest from './ajaxRequest';

export default function setupPusher() {

      const beamsClient = new PusherPushNotifications.Client({
        instanceId: 'ee5dbfe4-a1bc-4a79-88f1-9b68f0e78e01',
      });

      const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
          url: `https://api.agamus.it/staff-agamus/`,
          credentials: "include",
          queryParams: {
              token: JSON.parse(localStorage.getItem("vuex")).token,
              task : "pusher.tokenProvider",
          },
      });

      const startBeams = (client) => {

        ajaxRequest({
          A : {
            task : "utente/get",
            callback: (dt) => {
              client.start()
              .then(() => client.addDeviceInterest('agamus'))
              .then(() => client.setUserId(dt[0].IDutente, beamsTokenProvider))
              .then(() => showToast({title : `<i class="bi bi-bell"></i> Notifiche attivate`, content : `Puoi personalizzare le notifiche che vuoi ricevere tramite il tuo profilo utente.`}))
            }
          }
        })
      };
      
      beamsClient.getRegistrationState().then((state) => {
        switch (state) {
          case "PERMISSION_GRANTED_REGISTERED_WITH_BEAMS":
            beamsClient.getDeviceInterests().then((interests) => { console.log(`Notifiche già attive. Interests: ${interests.join()}`)})
            break;

          case "PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS":
            startBeams(beamsClient)
            break;

          case "PERMISSION_PROMPT_REQUIRED":
            msgbox({
              title : `<i class="bi bi-bell"></i> Notifiche`,
              content : `<p>Attiva le notifiche push su questo dispositivo. Potrai personalizzare le notifiche che vuoi ricevere tramite il tuo profilo utente.</p><p>Clicca sul pulsante <b>Attiva notifiche</b> e quindi fornisci l'autorizzazione all'invio di notifiche quando compare l'avviso.</p>`,
              actions : [
                {
                  text : "Attiva notifiche",
                  class : "btn-primary",
                  callback : function() {
                    startBeams(beamsClient);
                  }
                },
                {
                  text : "Non adesso",
                  class : "btn-secondary",
                  callback : function() {
                    
                  }
                }
              ],
              canDismiss: false,
            })
          break;

          case "PERMISSION_DENIED":
            console.log(`Notifiche bloccate`);
            showToast({
              title : `<i class="bi bi-bell-slash"></i> Notifiche bloccate`,
              content : `<p>Hai negato il permesso all'invio di notifiche.<br>
              <a href="#">Maggiori informazioni</a></p>`
            })
            break;
          default: 
            console.log(`Stato notifiche: ${state}`)


        }
      })
}