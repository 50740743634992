import $ from 'jquery';

export default async function login(user,pass,store) {

    return new Promise((resolve) => {

        $.ajax({
            url : process.env.VUE_APP_ENDPOINT + "/?login",
            data : { username : user, password : pass},
            method : 'POST',
            crossDomain: true,
            xhrFields: { withCredentials: true },
            dataType : 'json',
            success : function(dt) {
                if (dt.logged === true) {
                    store.commit("setUser",{username: user})
                    store.commit("setToken",dt.secret)
                    resolve({auth: true})

                   //toggleLogin();

                    if (sessionStorage.getItem("sspa_after_login_redirect") !== null) {
                        const url = sessionStorage.getItem("sspa_after_login_redirect")
                        sessionStorage.removeItem("sspa_after_login_redirect");
                        window.location.href = url;
                        //loadUrl(url);
                    } else {
                        //load("profilo");
                    }
                    
                    
                    //bootstrap.Modal.getOrCreateInstance(t[0]).hide();


                } else {
                    
                    resolve({ auth: false, msg : "I dati d'accesso non sono validi. Se non ricordi la tua password, usa la procedura di recupero password."})
                }
            },
            error : function(){
                resolve({ auth: false, msg : "Si è verificato un errore"})
            }
        })

    })
    
   



}