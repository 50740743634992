<template>
    <div>
        <h3>Menu</h3>

        <p>Benvenuto, <b>{{ $store.getters.getUser.username }}</b>!</p>

        <ul class="sidebar-ul" ref="menu">
            <li>
                <a href="#" @click="logout"><i class="bi bi-box-arrow-left"></i> Esci</a>
            </li>
            <li v-for="(item, index) in menu.items" :key="index">
                <hr v-if="item.type == 'separator'">
                <span v-if="item.type=='text'" >{{item.text}}</span>
                <h5 v-if="item.type == 'title'">{{ item.text }}</h5>
                <router-link v-if="item.type == 'link'" active-class="" :to="item.href"><i :class="item.icon"></i>
                    {{ item.text }}</router-link>

                <div v-if="item.type == 'group'" :id="`submenu-${index}`" class="group" :class="(item.show ? '' : 'hidden')">
                    <div v-if="item.type == 'group'" class="submenu-title" :data-target="`#submenu-${index}`"><strong>{{ item.text }}</strong> <i class="bi bi-chevron-down"></i></div>
                    <ul class="sidebar-ul submenu">
                        <li v-for="(item, index) in item.items" :key="index">
                            <hr v-if="item.type == 'separator'">
                            <h5 v-if="item.type == 'title'">{{ item.text }}</h5>
                            <router-link v-if="item.type == 'link'" active-class="" :to="item.href"><i
                                    :class="item.icon"></i> {{ item.text }}</router-link>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
/*

Segreteria

    Gestione soci
    Nuovo socio
    Nuovo corso
    Docenti
    Contabilità
    Registro accessi
    Insegnamenti
    Quote corsi

Amministrazione

    Gestione locali

AREA DOCENTI

    I tuoi corsi
    Foglio ore
    Disponibilità

SWCONFIG

    DigitalOcean
    BigBlueButton
    Gestione utenti
    Gestione permessi
    Gestione API
    Gestione crontab
    Log
    Analytics

COMUNICAZIONI

    Messaggi

EVENTI

    Matricolino
    Saggi
    Calendario

*/

import msgbox from '@/utils/msgbox';
import ajaxRequest from '@/utils/ajaxRequest';
export default {
    data() {
        return {
            menu: {
                items : [
                    { "type" : "text", "text": "Caricamento..."}
                ]
            }
        }
    },
    beforeCreate() {
        const __this = this;
        ajaxRequest({
            A : {
                task : "app/menu",
                callback : function(dt) {
                    __this.menu = dt;
                }
            }
        })
    },
    methods: {
        logout: function () {
            msgbox({
                title : "Esci",
                content : `Sei sicuro di voler effettuare il logut?`,
                canDismiss: false,
                actions: [
                    { text : "Sì", class : "btn-primary", callback : () => { this.$store.commit("logout") }},
                    { text : "No", class : "btn-secondary", callback : () => { return; }}
                ]
            })
            
        }
    },
    watch : {
        menu : {
            handler() {
            const __el = this.$el;
            [].slice.call(__el.querySelectorAll(".submenu-title")).map((smt) => {
                    smt.addEventListener("click",function() {
                    smt.querySelector(".bi").classList.toggle("bi-chevron-up")
                    smt.querySelector(".bi").classList.toggle("bi-chevron-down")
                    const sm = __el.querySelector(smt.getAttribute("data-target"));
                    sm.classList.toggle("hidden");

                })
            })
            },
            flush: 'post'
        }
    }
}
</script>
<style lang="scss">
$sidebar-width: 20rem;

.withsidebar .sidebar {
    width: $sidebar-width;
    min-width: $sidebar-width;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

.sidebar {
    z-index: 999;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: var(--sspa-sidebar);
    color: white;
    width: 0;
    left: -$sidebar-width;
    height: 100vh;
    overflow-x: hidden;

    .sidebar-content {
        padding: .5rem;
        width: $sidebar-width;
    }
}

.sidebar-ul {
    background: none;
    list-style: none;
    margin: 0;
    padding: 0;

    .submenu-title {
        position: relative;
        display: flex;
        background: none;
        border: 0;
        font-size: .9rem;
        text-transform: uppercase;
        letter-spacing: .08rem;
        color: white;
        padding:.5rem 0;
        cursor: pointer;
        

        i {
            opacity: .5;
        }

        &:hover i {
            opacity: 1;
        }

        strong {
            flex: 1;
        }
    }

    .group {

        border-bottom: 1px solid rgba(255,255,255,.1);
        
        .submenu {
             transition: all .2s ease-in;
            overflow: hidden;
        }
    
        &.hidden .submenu {
            transform: scaleY(0);
            height: 0;
        }
    }

    &.submenu {
        font-size: .9rem;
    }

    li {

        h5 {
            font-size: .9rem;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: .08rem;
        }

        background: none;
        transition: all .2s ease-in-out;
        border-color: rgba(255, 255, 255, .3);

        a {
            display: block;
            width: 100%;
            padding: .5rem .8rem;
            color: rgba(233, 236, 239, .5);
            text-decoration: none;
            border-left: 3px solid transparent;

            i {
                font-size: 1.3rem;
                margin-right: .4rem;
            }

            &.current {
                font-weight: bold;
                background: linear-gradient(90deg, rgba(59, 125, 221, .1), rgba(59, 125, 221, .088) 50%, transparent);
                border-left: 3px solid var(--bs-primary);
                color: rgba(233, 236, 239, .75);
            }
        }

        &:hover>a {
            color: rgba(233, 236, 239, .75);
        }
    }
}
</style>
