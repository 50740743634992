import './toast.scss';
import $ from 'jquery'
import { Toast } from 'bootstrap';
export function showToast(obj) {

    if (typeof(obj.type) === "undefined") {
        obj.type = "default";
    }

    

    const toast = $(`<div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <strong class="toast-title me-auto">`+obj.title+`</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">`+(obj.content || '')+`</div>
  </div>`)

  if (obj.type === "error") {
      toast.addClass("bg-danger text-white")
      toast.find(".toast-header").addClass("bg-danger text-white")
      toast.find(".btn-close").addClass("btn-close-white")
      toast.find(".toast-title").prepend('<i class="bi bi-exclamation-triangle-fill"></i> ')
  }

  if (obj.type === "success") {
    //toast.addClass("bg-primary text-white")
    toast.find(".toast-header").addClass("bg-primary text-white")
    toast.find(".btn-close").addClass("btn-close-white")
    toast.find(".toast-title").prepend('<i class="bi bi-hand-thumbs-up"></i> ')
}

    $("#toast-area").append(toast)
    $("#toast-area").show();

    const options = {};
    ["autohide","animation","delay"].forEach((conf) => {
        if (typeof(obj[conf]) !== "undefined") options[conf] = obj[conf];
    })
    

    const bt = new Toast(toast[0],options)
    bt.show()
    toast[0].addEventListener("hidden.bs.toast",function(e) {
        $(e.target).remove();
        if ($("#toast-area .show").length == 0) {
            $("#toast-area").hide();
        }
    })
}

export function createToastArea() {
    $("body").append(`<div id="toast-area" class="toast-container"></div>
    `)
}