<template>
  <nav class="navbar bg-light topbar-nav">
    <div class="container-fluid">
      <a class="navbar-brand" @click="$emit('toggleSidebar')"><i class="bi bi-list"></i></a>

     
   
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link class="nav-link" to="/profilo"><i class="bi bi-person-fill"></i></router-link>
        </li>
        
      </ul>

    </div>
  </nav>
</template>
<script>
//import { Dropdown } from 'bootstrap'
export default {
  name: 'TopbarNav',
  props: {
    isSidebarOpen: Boolean,
  },
  mounted() {
    // const dd = new Dropdown(this.$el.querySelector("#dropdownProfilo"))
    // dd.show()
  }
}
</script>
<style lang="scss">
.topbar-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, .3);
  z-index: 999;
}
</style>
