<template>
  <div class="wrapper" :class="{ withsidebar: isSidebarOpen}">
    <nav v-if="$store.getters.isLogged" class="sidebar">
      <div class="sidebar-content">
        <SidebarMenu/>
      </div>
    </nav>
    <div class="main" v-if="$store.getters.isLogged" >
      <TopbarNav @toggleSidebar="toggleSidebar" :is-sidebar-open="isSidebarOpen"></TopbarNav>
      <div class="module" @click="clickOnMain">
        <router-view></router-view>
      </div>
    </div>
    <LoginComponent v-if="!$store.getters.isLogged"></LoginComponent>
    <div v-if="$store.getters.isLogged" class="sidebar-toggler text-white" @click="toggleSidebar">
      <i class="bi bi-list"></i>
    </div>
    <LoaderComponent :visible="$store.getters.isLoading" />
  </div>
</template>
<script>
require("@popperjs/core")
require("bootstrap")

import '../node_modules/bootstrap/scss/bootstrap.scss';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import SidebarMenu from '@/components/SidebarMenu.vue';
import TopbarNav from '@/components/TopbarNav.vue'
import LoginComponent from './components/LoginComponent.vue';
import { createToastArea } from './utils/toast';
import setupPusher from '@/utils/pusher';
import LoaderComponent from '@/components/LoaderComponent.vue';
export default {
  name : "App",
  components : {
    SidebarMenu,
    TopbarNav,
    LoginComponent,
    LoaderComponent
  },
  data() {
    return {
      isSidebarOpen: true,
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    clickOnMain() {
      if (window.innerWidth < 768 && this.isSidebarOpen) {
        this.toggleSidebar()
      }
    }
  },
  beforeMount() {
    createToastArea()
  },
  mounted() {
    if (window.innerWidth < 768 && this.isSidebarOpen) {
      this.toggleSidebar()
    }

    if (process.env.NODE_ENV === 'production') {
      if (this.$store.getters.isLogged) {
        setupPusher();
      }
      
    }
  },
}
</script>
<style lang="scss">
@use "./theme-dark.scss";
@use "./bootstrap-v4-retrocompatibility.scss";

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;700&display=swap');

$topbar-height: 3.5rem;
$speed: 2s;

body, html {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;

  &.hidden {
    display: none;
  }

  

    .sidebar-toggler {
      display: block;
      position: fixed;
      top: .5rem;
      left: 0;
      font-size: 1.2rem;
      background: var(--bs-primary);
      padding: 0.4rem 0.5rem .4rem .7rem;
      border-radius: 0 .3rem .3rem 0;
      box-shadow: 1px 1px 10px rgba(0,0,0,.4);
    }

    &.withsidebar .sidebar-toggler {
      left: 20rem;
    }

  .main {
    position: relative;
    padding-top: $topbar-height;
    padding-left: 0;
    width: 100%;
    

    .module {
      background-color: #F5F7FB;
      min-height: calc(100vh - $topbar-height);
    }
  }

  

}
</style>