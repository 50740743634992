import $ from 'jquery';
import {Modal}  from 'bootstrap';
export default function msgbox(mixed, content, options) {
	
	if ("undefined" === typeof(content)) content = "";
	if ("undefined" === typeof(options)) options = {};

    let title;
	
	if ("object" == typeof(mixed)) {
		title = mixed.title;
		content = mixed.content;
		options = mixed;
	} else {
		title = mixed;
	}
	
	if (typeof(options.canDismiss) === "undefined") options.canDismiss = true;
	
	var modalId = 'msgbox-'+parseInt(Math.random()*100000);

    let size = "";
    if (options.large === true) {
        size = "modal-lg";
    }
    if (options.size) {
        size = "modal-"+options.size;
    }
    
    const m = $(`<div class="modal sspa-msgbox" id="`+modalId+`" tabindex="-1">
    <div class="modal-dialog `+size+`">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">`+('undefined'===typeof(title) ? '' : title)+`</h5>
        </div>
        <div class="modal-body">
        `+content+`
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
  `)

    const createBtn = (action) => {
        const btn = $('<button '+(action.noDismiss && action.noDismiss===true ? '' : 'data-bs-dismiss="modal"')+' class="btn '+(action.class || "")+'">'+action.text+'</button>')
        btn.click(() => {
            action.callback(m)
        })
        m.find(".modal-footer").append(btn)
    }
    
    if (typeof(options.action) !== "undefined") {
        createBtn(options.action)
    }

    if (typeof(options.actions) !== "undefined") {
        options.actions.forEach((action) => createBtn(action));
    }

    if (options.canDismiss === true) {
        m.find(".modal-footer").append('<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Chiudi</button>')
        m.find(".modal-header").append('<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>')
    }

    if (options.footer === false && !options.action && !options.actions) {
        m.find(".modal-footer").remove();
    }
  
  $(".sspa-msgbox").each(function() {
    if (!$(this).is(":visible")) {
      $(this).remove()
    }
  })

  $("body").append(m)
	
	const mymodal = new Modal($("#"+modalId)[0],{
		backdrop : (options.canDismiss==false ? 'static' : true),
        keyboard : (options.canDismiss==false ? false : true)
	});
    mymodal.show();
	
	if ("function" === typeof(options.onHide)) {
		$("#"+modalId).on('hide.bs.modal',options.onHide)
	}
	
	if ("function" === typeof(options.onHidden)) {
		$("#"+modalId).on('hidden.bs.modal',options.onHidden)
	}
	
	if ("object" === typeof(options.action)) {
		if ("function" === typeof(options.action.callback))
		$('#'+modalId).find('.modal-action').click(function() {options.action.callback($('#'+modalId)) });
	}
	
	if (typeof(options.actions) === 'object') {
		for (var idb=0; idb<options.actions.length; idb++) {
			var action = options.actions[idb];
			if ("function" === typeof(action.callback))
			$('#'+modalId).find('#action-'+idb).click(function() {
				var idb = parseInt($(this).attr("data-idb"));
				options.actions[idb].callback($('#'+modalId)) 
			});
		}
	}
	
	return $("#"+modalId);

}