import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import '../node_modules/bootstrap/dist/js/bootstrap';



const store = new Vuex.Store({
    plugins : [
        createPersistedState({
            storage: window.localStorage
        })
    ],
    state : {
            user : false,
            token : false,
            loading: false,
    },
    mutations : {
        setUser(state, user) {
            state.user = user;
        },
        setToken(state, token) {
            state.token = token;
        },
        logout(state) {
            state.token = false;
            state.user = false;
        },
        setLoader(state,visible) {
            state.loading = visible;
        }
    },
    getters: {
        isLogged(state) {
          return !(state.token===false)
        },
        getUser(state) {
            return state.user;
        },
        isLoading(state) {
            return state.loading;
        }
    }

})

let loaderDelay = null;

router.beforeEach(() => {
    if (loaderDelay !== null) { window.clearTimeout(loaderDelay)}
    loaderDelay = window.setTimeout(() => {
        store.commit("setLoader",true)
    },process.env.VUE_APP_LOADER_DELAY);
    
    if (window.innerWidth < 768 && document.querySelector(".wrapper").classList.contains("withsidebar")) {
      document.querySelector(".sidebar-toggler").dispatchEvent(new Event("click"))
    }
  })
  
  router.afterEach(() => {
    if (loaderDelay !== null) { window.clearTimeout(loaderDelay)}
    store.commit("setLoader",false)
  })

//import { setupLegacyMode } from './legacy_support/legacy';
//setupLegacyMode(router);

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
