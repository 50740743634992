<template>
    <div class="loader" :class="{visible : visible}">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>
<script>
export default {
    props : ["visible"]
}
</script>
<style lang="scss">
.loader {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    cursor: wait;
    display: none;
    place-items: center;
    transition: all .5s linear;
    background-color: rgba(255,255,255,.3);
    z-index: 1000;

    &.visible {
        display: grid;
        opacity: 1;
    }
}
</style>