import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '@/components/NotFound';

const routes = [
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]

const fastImport = (name) => {
  if (name.split("/").length === 1) {
    name = `${name}/${name}`
  }
  return () => import(
    /* webpackMode: "lazy" */
    `../modules/${name}View.vue`)
}

routes.push({
  name: "Home",
  path: `/`,
  component: fastImport("Home")
})

routes.push({
  name: "Profilo",
  path: `/profilo`,
  component: fastImport("Profilo")
})

routes.push({
  name: "I tuoi corsi",
  path: `/corsi`,
  component: fastImport("Corsi")
})

routes.push({
  name: "Corso",
  path: `/corso/:IDcorso`,
  component: fastImport("Corso")
})

routes.push({
  name: "Foglio ore",
  path: `/foglio-ore`,
  component: fastImport("FoglioOre")
})

routes.push({
  name: "Disponibilità",
  path: `/disponibilita`,
  component: fastImport("Disponibilita")
})

routes.push({
  name: "About",
  path: `/about`,
  component: fastImport("About")
})

routes.push({
  name: "Modulo",
  path: `/modulo/:id`,
  component: fastImport("Modulo")
})

routes.push({
  name: "Socio",
  path: `/socio/:ids`,
  component: fastImport("Socio"),
  children: [
    {
      path: "pagamenti",
      component: fastImport("Socio/Pagamenti")
    }
  ]
})

routes.push({
  name: "Login",
  path: `/login`,
  component: import("@/components/LoginComponent.vue"),
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
