<template>
    <div class="container container-login">
        <div class="card card-login">
            <div class="card-body">
                <h5 class="card-title">Effettua il login</h5>

                <form @submit.prevent="customLogin">
                    <div class="form-group">
                        <label>Username</label>
                        <input v-model="username" required type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input v-model="password" required type="password" class="form-control">
                    </div>
                    <hr>
                    <button class="btn btn-primary" type="submit">
                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {{ loginText }}
                    </button>

                    <div v-if="errorMsg!==false" class="alert alert-danger mt-3">{{errorMsg}}</div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import isLogged from "@/auth/isLogged";
import login from '@/auth/login'
export default {
    beforeCreate() {
        if (isLogged()) {
            this.$router.push("/")
        }
    },
    data() {
        return {
            username : "",
            password : "",
            loading: false,
            loginText : "Login",
            errorMsg : false,
        }
    },
    methods: {
        customLogin: async function () {

            this.loading = true;
            this.loginText = "Accesso in corso";
            const result = await login(this.username, this.password, this.$store)

            if (result.auth === true) {
                this.$router.push("/")
            } else {
                this.loading = false;
                this.loginText = "Login";
                this.password = "";
                this.errorMsg = result.msg
            }

        }
    }
}
</script>
<style lang="scss">
.container-login {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
}

.card-login {
    max-width: 400px;
}
</style>