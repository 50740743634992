import $ from 'jquery';

export function createSpinner() {
    const div = $(`
    <div id="spinner" class="spinner-container">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>`)

    $("body").append(div)
}

export function spinner(status) {
    if (status == 0) {
        $("#spinner").removeClass("show");
        // if (typeof(window.spinnerTimeout) !== "undefined") {
        //     clearTimeout(spinnerTimeout);
        // }
        // window.spinnerTimeout = setTimeout(() => {
            
        // }, 500)
    } else {
        $("#spinner").addClass("show");
    }
}